import { isRouteErrorResponse, useRouteError } from '@remix-run/react';
import { Markdown } from '@shiftsmartinc/shiftsmart-ui';

export interface ApplicationErrorBoundaryProps {
  className?: string;
  'data-testid'?: string;
}

/**
 * @external https://remix.run/docs/en/main/route/error-boundary
 */
export const ApplicationErrorBoundary = (
  props: ApplicationErrorBoundaryProps,
) => {
  const {
    className = 'p-4',
    'data-testid': dataTestId = 'ApplicationErrorBoundary',
  } = props;

  // Hooks
  const error = useRouteError();

  // Setup
  const isError = error instanceof Error;
  const isRouteError = isRouteErrorResponse(error);

  // Handlers

  // Markup

  // Life Cycle

  // 🔌 Short Circuits
  // TODO: if (!isAuthenticated) ...

  if (isRouteError) {
    return (
      <div className={className} data-testid={dataTestId}>
        <h1 className="text-title">
          {error.status} {error.statusText}
        </h1>
        <Markdown className="mt-4" content={error.data} />
      </div>
    );
  }

  if (isError) {
    return (
      <div className={className} data-testid={dataTestId}>
        <h1 className="text-title">Error Message:</h1>
        <p>{error.message}</p>

        <h2 className="text-subtitle -mb-2 mt-4">Stack trace:</h2>
        <Markdown content={error.stack} />
      </div>
    );
  }

  return (
    <div className={className} data-testid={dataTestId}>
      <h1 className="text-xl">Unknown Error</h1>
      <p>
        Sorry we&apos;ve encountered an unknown error. Please try again later.
      </p>
    </div>
  );
};
